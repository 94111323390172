/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

html {
  scroll-behavior: smooth;
}

.App {
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 100vw;
  overflow: hidden;
  background-color: #223;
}

.navcontainer {
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

.navmenu {
  display: flex;
  justify-content: center;
  margin: 0px;
  background-color: #334;
}

.menuitem {
  color: #EEE;
  z-index: 4;
  padding: 15px;
  border: 1px solid #EEE;
  cursor: pointer;  
}

.navmenu a {
  color: inherit;
  text-decoration: none;
}

.current, .menuitem:hover {
  text-decoration: underline;
  background-color: #223;
}

.routecontainer {
  align-self: flex-start;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width:768px), 
   screen and (max-device-width:768px)
{
  .menuitem {
    font-size: 14px;
    padding: 10px;
  }
}