.fourohfour {
    color: #EEE;
    font-size: 30px;
    align-self: flex-start;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
    font-family: 'Titillium Web';
    height: 100vh;
}
      

.errortext {
    width: 80vw;
    margin-top: 100px;
}
