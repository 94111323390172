@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;400&display=swap');

.Home {
    color: #EEE;
    font-size: 30px;
    align-self: flex-start;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
    font-family: 'Titillium Web';
}
  
.canvascontainer {
    width: 100vw;
    min-height: 500px;
    height: 500px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    justify-content: center;
    align-items: center;
}

.horizontalbar {
    background-color: #445;
    width: 35vw;
    max-width: 300px;
    height: 3px;
    min-width: none;
    margin: 15px;
    align-self: center;
}
  
.canvascontainer div {
    grid-row: 1;
    grid-column: 1;
}


.carouselcontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
    width: 100%;
}

.swiper {
    width: 100%;
}

.title {
    text-align: center;
    font-size: 34px;
    align-self: center;
    font-weight: 400;
    width: 100vw;
    padding: 80px 0;
    background-color: #334;
    border-radius: 0 0 50% 50%;
    margin-bottom: -50px;
    z-index: 4;
}

.subtitle {
    font-size: 28px;
    margin: 10px;
}

.subtext {
    font-size: 18px;
    color: #667;
}

.homecontent {
    width: calc(100vw - 300px);
    background-color: #334;
    border-radius: 50%;
    padding: 100px 200px;
    font-weight: 100;
    font-size: 24px;
    margin-top: -50px;
    z-index: 4;
}

.portfolio {
    width: 90vw;
    border-radius: 50%;
    padding: 20px 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
    margin-top: 150px;
    z-index: 4;
}

.projects {
    display: flex;
    flex-direction: column;
    width: 90vw;
    align-items: center;
    justify-content: flex-start;
}

.projectcontainer {
    width: 270px;
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    border-radius: 12px;
    font-size: 18px;
    z-index: 2;
}

.projectcontaineroverlay {
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: opacity 0.25s ease-in-out;
    border-radius: 9px;
}

.projectcontaineroverlay:hover {
    opacity: 0;
}

.projectcontainer a {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-color: inherit;
    color: inherit;
}

.projectcontainercontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin: 0px;
    width: 276px;
}

.projectcontainercontainercontainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-size: 18px;
    align-items: center;
    justify-content: flex-start;
    margin: 15px;
    max-width: 1600px;
}

.projectdescription {
    height: 170px;
    border: 3px solid gray;
    margin-left: -35px;
    z-index: -1;
    padding: 10px 20px 10px 40px;
    display: flex;
    align-items: center;
    border-radius: 12px;
    background-color: #334;
}

.project {
    margin: 12px;
}

.sourcecode {
    border: 3px solid gray;
    border-radius: 0 0 12px 12px;
    background-color: #112;
    width: calc(100% - 6px);
    font-size: 18px;
    height: 45px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: -20px;
}

.statGenerator {
	color: #FFF;
    background-image: url('../components/images/StatGenerator.png');
    background-size: cover;
    background-position: center;
	text-align: center;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
	font-weight: bold;
	display: flex;
	flex-direction: column;
	justify-content: center;
    border: 3px solid;
    border-color: gray;
}

.SSBSite {
	color: #FFF;
    background-image: url('../components/images/ChurchSite.png');
    background-size: cover;
    background-position: center;
	text-align: center;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
	font-weight: bold;
	display: flex;
	flex-direction: column;
	justify-content: center;
    border: 3px solid;
    border-color: gray;
}

.KIT {
	color: #FFF;
    background-image: url('../components/images/KIT.png');
    background-size: cover;
    background-position: center 10%;
	text-align: center;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
	font-weight: bold;
	display: flex;
	flex-direction: column;
	justify-content: center;
    border: 3px solid gray;
}

.CC {
	color: #FFF;
    background-image: url('../components/images/CardCollector.png');
    background-size: cover;
    background-position: center;
	text-align: center;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
	font-weight: bold;
	display: flex;
	flex-direction: column;
	justify-content: center;
    border: 3px solid gray;
}

.oldportfolio {
	color: #FFF;
    background-image: url('../components/images/OldSite.png');
    background-size: cover;
    background-position: center;
	text-align: center;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
	font-weight: bold;
	display: flex;
	flex-direction: column;
	justify-content: center;
    border: 3px solid gray;
}

.about {
    width: 50vw;
    max-width: 380px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 150px;
    margin-top: -150px;
}

.about p {
    font-size: 18px;
}

.contactinfo {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    margin-bottom: 100px;
}

@media screen and (max-width:768px), 
   screen and (max-device-width:768px)
{
    .homecontent {
        width: calc(100vw - 100px);
        padding: 40px 150px;
    }
    
    .projectcontainercontainercontainer {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        font-size: 18px;
        align-items: center;
        justify-content: flex-start;
    }

    .projectdescription {
        height: 300px;
        margin-left: 0;
        margin-top:-50px;
        width: 70vw;
        z-index: -1;
        padding: 20px;
        display: flex;
        align-items: center;
        border-radius: 12px;
        background-color: #556;
    }
}