@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;400&display=swap');

.Resume {
    width: 100vw;
    align-self: flex-start;
    color: #EEE;
    padding: 0px;
    font-family: 'Titillium Web';
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

a {
    color: #EEE;
}

.resumetitle {
    text-align: center;
    font-size: 34px;
    font-weight: 400;
    width: 100vw;
    background-color: #334;
    border-radius: 0 0 50% 50%;
    padding: 40px;
    margin-top: 40px;
    z-index: 4;
}

.resumesection {
    width: 70vw;
    max-width: 400px;
}

.skills {
	display: grid;
	grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    align-items: flex-start;
	justify-content: stretch;
    width: 70vw;
}

.skills div {
    width: 100%;
    text-align: center;
}


@media screen and (max-width:768px), 
   screen and (max-device-width:768px)
{
    .Resume {
        padding: 20px;
    }
}